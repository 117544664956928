<template>
  <main class="app-container w1200">
    <breadcrumb />
    <div class="product-card">
      <div class="pruduct-title">
        <span>美国小包专线</span>
        <img src="../../assets/images/product/us-flag.png" alt="" />
      </div>
      <div class="product-list">
        <div class="product-item">
          <img
            src="../../assets/images/product/product-desc1.png"
            alt=""
            class="product-img"
          />
          <div class="product-content">
            <p class="product-name">美国超级专线小包</p>
            <p class="product-time">全程时效5-8个工作日</p>
            <div class="product-desc">
              <p>时效快速</p>
              <p>可定制化快线产品</p>
              <p>可提供虚拟仓服务</p>
            </div>
          </div>
        </div>
        <div class="product-item">
          <img
            src="../../assets/images/product/product-desc2.png"
            alt=""
            class="product-img"
          />
          <div class="product-content">
            <p class="product-name">美国特惠专线小包</p>
            <p class="product-time">全程时效8-15个工作日</p>
            <div class="product-desc">
              <p>性价比高</p>
              <p>多尾程组合</p>
              <p>时效稳定</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="pruduct-title">
        <span>英国小包专线</span>
        <img src="../../assets/images/product/uk-flag.png" alt="" />
      </div>
      <div class="product-list">
        <div class="product-item">
          <img
            src="../../assets/images/product/product-desc3.png"
            alt=""
            class="product-img"
          />
          <div class="product-content">
            <p class="product-name">英国专线小包</p>
            <p class="product-time">全程时效4-7个工作日</p>
            <div class="product-desc">
              <p>固定舱位</p>
              <p>快件清关</p>
              <p>本土优势尾程派送</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="pruduct-title">
        <span>德国小包专线</span>
        <img src="../../assets/images/product/ge-flag.png" alt="" />
      </div>
      <div class="product-list">
        <div class="product-item">
          <img
            src="../../assets/images/product/product-desc4.png"
            alt=""
            class="product-img"
          />
          <div class="product-content">
            <p class="product-name">德国专线小包</p>
            <p class="product-time">全程时效5-7个工作日</p>
            <div class="product-desc">
              <p>固定舱位</p>
              <p>快件清关</p>
              <p>本土优势尾程派送</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  name: "about",
  data() {
    return {
      aboutData: [
        {
          title: "美国小包经济专线",
          timespace: "全程时效7-16个工作日",
          descrip: ["经济实惠", "预清关", "USPS派送", "天天固定优质航班"],
        },
        {
          title: "美国小包特货",
          timespace: "全程时效7-16个工作日",
          descrip: ["可发洗手液、湿纸巾", "预清关", "USPS派送", "正规渠道出货"],
        },
        {
          title: "卢森堡邮政小包",
          timespace: "全程时效10-15个工作日",
          descrip: [
            "时效稳定、全程查控",
            "卢邮商业清关更稳妥",
            "高性价比、畅通西欧",
          ],
        },
        {
          title: "英国小包专线",
          timespace: "全程时效4-10个工作日",
          descrip: ["直达英国", "固定航班", "可接内置电池"],
        },
        {
          title: "加拿大专线",
          timespace: "全程7-15工作日妥投",
          descrip: [
            "多口岸直飞",
            "预清关",
            "加拿大邮政“Expedited”派送服务     ",
            "可接食物、化妆品等敏感货，接货属性多样",
          ],
        },
        {
          title: "日本小包专线",
          timespace: "全程4-6工作日妥投",
          descrip: [
            "时效稳定、全程查控",
            "可接直接接触食物、人体等货物，接货属性多样",
          ],
        },
        {
          title: "深圳E邮宝",
          timespace: "",
          descrip: ["深圳直出E邮宝", "时效稳定", "覆盖范围广"],
        },
        {
          title: "广西E邮宝",
          timespace: "",
          descrip: ["10个主流国家可发膏状物、化妆品、液体（100ml内）等敏感货"],
        },
      ],
    };
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.product-card {
  margin-bottom: 35px;
  width: 100%;
  .pruduct-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #4a4a4a;
    margin-bottom: 37px;
    span {
      font-size: 18px;
      color: #010101;
      line-height: 32px;
      position: relative;
      font-family: SourceHanSansCN-Bold;
      &::after {
        content: "";
        display: block;
        height: 4px;
        background: #e2385c;
        position: absolute;
        width: 100%;
        bottom: -3px;
      }
    }
    img {
      width: 51px;
      height: 29px;
    }
  }
  .product-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 35px;
    .product-img {
      width: 358px;
      height: 198px;
      margin-right: 40px;
    }
    .product-name {
      font-size: 20px;
      color: #010101;
      line-height: 26px;
      margin-top: 14px;
      margin-bottom: 13px;
    }
    .product-time {
      font-size: 16px;
      color: #e2385c;
      line-height: 26px;
      margin-bottom: 15px;
    }
    .product-desc {
      font-size: 14px;
      color: #010101;
      line-height: 26px;
    }
  }
}
</style>
